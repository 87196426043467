import { defineStore } from "pinia";

export enum DialogTypes {
    ERR = "ERR",
    SNACKBAR = "SNACKBAR",
    HOSPITALITY = "HOSPITAlITY",
    NEWSLETTER = "NEWSLETTER",
    MESSAGE = "MESSAGE",
    CARD_MANAGEMENT = "CARDMANAGEMENT"
}

export const useDialogsStore = defineStore("dialogs", () => {
    const type = ref<string | null>(null);
    const color = ref<string | null>(null);
    const args = ref<any>(null);

    function open(t: DialogTypes, a?: any, c?: any) {
        type.value = t;
        color.value = c;
        args.value = a;
    }

    function close() {
        type.value = null;
        color.value = null;
        args.value = null;
    }

    return { type, args, color, open, close };
});